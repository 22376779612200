module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.5_babel-eslint@10.1.0_eslint@8.57.0__esbuild@_ba023f4745d09d36396400bcb9fce0ca/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.svg","name":"Warboxcreative","short_name":"Warboxcreative","start_url":"/","background_color":"#052530","theme_color":"#052530","display":"standalone","orientation":"portrait","icon_options":{"purpose":"any"},"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c9845c2626953918a74eb76b936767d0"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.5_babel-eslint@10.1.0_eslint@8.57.0__586c7ae8fde07fb006b30405ef26b653/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KSWMVGC","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.5_babel-eslint@10.1.0_eslint@8.57.0__esbuild@0.21.3_react-dom@18.3.1_react@_2b929c65bc1890681276e8ca86cdfe07/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
